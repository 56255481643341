<div style="position:absolute;left:-15420px;font-size: 8pt;">
  <div style="position:relative;width:21cm;height:14.8cm;">

    <div id="purchaseOrderInvoice">
      <div class="container">
        <div class="page-header">
          <div class="brand-section">
            <div class="row" style="display: flex;flex-wrap: wrap;">
              <div style="width: 60%;flex: 0 0 auto;">
                <!-- <span
                                      style="margin-bottom: 0px !important;font-size: 10pt;text-transform: uppercase;">{{companyProfile?.title}}</span> -->
                <span *ngIf="isquatation"
                  style="margin-bottom: 0px !important;font-size: 10pt;text-transform: uppercase;">{{'PURCHASE_ORDER_REQUEST' | translate}}</span>
                <span *ngIf="!isquatation"
                  style="margin-bottom: 0px !important;font-size: 10pt;text-transform: uppercase;">{{'PURCHASE_ORDER_INVOICE' | translate}}</span>
                <div class="company-details">
                  <img *ngIf="!companyProfile.logoUrl" class="img-fluid" style="max-height:50px;
                                                object-position: left;" src="../../../assets/images/logo.png" />
                  <img *ngIf="companyProfile.logoUrl" class="img-fluid" style="max-height:50px;
                                                object-position: left;" [src]="companyProfile.logoUrl" />
                </div>
                <div>
                  <span style="font-size: 10pt;text-transform: uppercase;">{{'INVOICE_NUMBER' | translate}}:
                    {{purchaseOrderForInvoice?.orderNumber}} </span>
                </div>

              </div>
              <div style="width: 40%;flex: 0 0 auto;">

                <div>
                  <span style="font-size: 10pt;text-transform: uppercase;"> {{'ORDER_DATE' | translate}} : {{purchaseOrderForInvoice?.poCreatedDate
                                                      | date:'dd/MM/yyyy'}}</span>
                </div>
                <div *ngIf="purchaseOrderForInvoice?.supplier?.supplierName">
                  <b>{{'Supplier' | translate | uppercase}}:</b>
                  {{purchaseOrderForInvoice?.supplier?.supplierName}} <br>
                  <!-- <div *ngIf="purchaseOrderForInvoice.supplier?.billingAddress" style="width:50%;">
                                                                        <span> {{purchaseOrderForInvoice.supplier?.billingAddress}} </span>
                                                                      </div> -->
                </div>
                <div *ngIf="purchaseOrderForInvoice?.supplier?.mobileNo">
                  <b>{{'PHONE' | translate}}:</b>
                  {{purchaseOrderForInvoice?.supplier?.mobileNo}} <br>
                </div>
                <div *ngIf="companyProfile?.address">
                  {{companyProfile?.address}} <br>
                </div>
                <div>
                  <div *ngIf="!companyProfile?.phone.includes('x')">
                    - {{companyProfile?.phone}} <br>
                  </div>
                  <!-- <div *ngIf="companyProfile?.email">
                                                                            <b>{{'EMAIL' | translate}}:</b>
                                                                            {{companyProfile?.email}}
                                                                        </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="page-footer">

          <div class="row" style="width:100%">
            <div style="display:flex;flex-wrap: wrap;">
              <div class="col-4" style="width:33%;">
                {{'BEST_REGARDS' | translate | uppercase}}
                <br><br><br>___________________<br>
                &nbsp;

              </div>
              <div class="col-4" style="width:33%;">
                {{'RECEIPT' | translate | uppercase}}
                <br><br><br>___________________<br>
                &nbsp;

              </div>
              <div class="col-4" style="width:33%;">

              </div>
            </div>
          </div>
        </div>
      </div>

      <table style="width:100%;border:none;">
        <thead>
          <tr style="border:none;">
            <td style="border:none;">
              <div class="page-header-space">&nbsp;</div>
            </td>
          </tr>
        </thead>
        <tbody style="border:none;">
          <tr style="border:none;">
            <td style="border:none;">
              <div class="page">
                <table class="table-bordered"
                  style="background-color: #fff;width:100%;border-collapse:collapse;font-size: 10pt;">
                  <thead>
                    <tr style="border:1px solid #111;background-color:#f2f2f2;">
                      <th
                        style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:0.5cm;">
                        {{'NO.' | translate}}
                      </th>
                      <th
                        style="padding-top:08px;padding-bottom:08px;text-align:left;border:1px solid #111;text-transform: uppercase; width:7.2cm;">
                        {{'PRODUCT_NAME' | translate}}</th>

                      <!-- <th class="w-20"
                                  style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:1.68cm;">
                                  {{'QTY' | translate}}</th> -->
                      <th
                        style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:2cm;">
                        {{'QTY' | translate}}</th>
                      <!-- <th class="w-20"
                                  style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:3.2cm;">
                                  {{'DISCOUNT' | translate}}</th> -->
                      <!-- <th class="w-20"
                                                    style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:3.2cm;">
                                                    {{'TAX' | translate}}</th> -->
                      <th
                        style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:2.5cm;">
                        {{'UNIT_PRICE' | translate}}</th>
                      <th
                        style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:3cm;">
                        {{'TOTAL_AMOUNT' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let purchaseItem of purchaseOrderItems;let i=index">
                      <td style="vertical-align:middle!important;text-align:center;border:1px solid #111;">
                        {{i+1}}</td>
                      <td style="vertical-align:middle!important;text-align:left;border:1px solid #111;">
                        {{purchaseItem.productName}}</td>


                      <td style="vertical-align:middle!important;text-align:left;border:1px solid #111;">
                        {{purchaseItem.quantity}} {{purchaseItem.unitName}}</td>
                      <!-- <td style="vertical-align:middle!important;text-align:center;border:1px solid #111;">
                                  {{purchaseItem.unitName}}</td> -->

                      <!-- <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                                  {{purchaseItem.discount | customCurrency}}</td> -->
                      <!-- <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                                                    {{purchaseItem.taxValue | customCurrency}}<br>
                                                    <span style="font-size: 10px;"
                                                        *ngFor="let tax of purchaseItem.purchaseOrderItemTaxes;let last=last">
                                                        {{tax.taxName}}({{tax.taxPercentage}}%) <span *ngIf="!last">, </span>
                                                    </span>
                                                </td> -->
                      <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                        {{purchaseItem.unitPrice | customCurrency}}</td>
                      <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                        {{(purchaseItem?.quantity * purchaseItem?.unitPrice) - purchaseItem.discount +
                                                    purchaseItem.taxValue | customCurrency }}
                      </td>
                    </tr>
                    <tr style="vertical-align:middle!important;border:1px solid #111;background-color: blanchedalmond;"
                      *ngIf="purchaseOrderReturnsItems.length > 0">
                      <td colspan="100%"><strong> {{'RETURNED_ITEMS' | translate | uppercase}}</strong></td>
                    </tr>
                    <tr style="background-color: blanchedalmond;;"
                      *ngFor="let purchaseItem of purchaseOrderReturnsItems;let i=index">
                      <td style="vertical-align:middle!important;text-align:center;border:1px solid #111;">
                        {{i+1}}</td>
                      <td style="vertical-align:middle!important;text-align:left;border:1px solid #111;">
                        {{purchaseItem.productName}}</td>

                      <td style="vertical-align:middle!important;text-align:left;border:1px solid #111;">
                        {{purchaseItem.quantity}} {{purchaseItem.unitName}}</td>
                      <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                        {{purchaseItem.unitPrice | customCurrency}}</td>

                      <!-- <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                                  {{purchaseItem.discount | customCurrency}}</td> -->
                      <!-- <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                                                    {{purchaseItem.taxValue | customCurrency}}<br>
                                                    <span style="font-size: 10px;"
                                                        *ngFor="let tax of purchaseItem.purchaseOrderItemTaxes;let last=last">
                                                        {{tax.taxName}}({{tax.taxPercentage}}%) <span *ngIf="!last">, </span>
                                                    </span>
                                                </td> -->
                      <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                        {{(purchaseItem?.quantity * purchaseItem?.unitPrice) - purchaseItem.discount +
                                                    purchaseItem.taxValue | customCurrency }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-right"
                        style="text-align:end;border:1px solid #111;font-weight: bold;">
                        {{'TOTAL_ITEM' | translate | uppercase}}
                      </td>
                      <td class="text-center" style="text-align:left;border:1px solid #111;font-weight: bold;">
                        {{purchaseOrderForInvoice.totalQuantity}} {{'ITEM' | translate | uppercase}}
                      </td>
                      <td colspan="1" class="text-right"
                        style="text-align:end;border:1px solid #111;font-weight: bold;">
                        {{'SUBTOTAL' | translate | uppercase}}
                      </td>
                      <!-- <td class="text-right" style="text-align:end;border:1px solid #111;font-weight: bold;">
                                  {{purchaseOrderForInvoice.totalDiscount | customCurrency}}
                                </td> -->
                      <!-- <td class="text-right"
                                                    style="text-align:end;border:1px solid #111;font-weight: bold;">
                                                    {{purchaseOrderForInvoice.totalTax | customCurrency}}
                                                </td> -->

                      <td style="text-align:end;border:1px solid #111;font-weight: bold;">
                        {{purchaseOrderForInvoice.totalAmount | customCurrency}}</td>
                    </tr>
                    <!-- <tr>
                                <td colspan="4" class="text-right" style="text-align:end;border:1px solid #111;font-weight: bold;">
                                  {{'PAID' | translate}}</td>
                                <td style="text-align:end;border:1px solid #111;">
                                  {{purchaseOrderForInvoice.totalPaidAmount | customCurrency}} </td>
                              </tr> -->
                    <tr>
                      <td colspan="4" class="text-right"
                        style="text-align:end;border:1px solid #111;font-weight: bold;">
                        {{'TOTAL' | translate | uppercase}} </td>
                      <td style=" text-align:end;border:1px solid #111;">
                        {{purchaseOrderForInvoice.totalAmount-purchaseOrderForInvoice.totalPaidAmount |
                                                    customCurrency}}
                      </td>
                    </tr>
                    <!-- <tr>
                                <td colspan="6" class="text-right" style="text-align:end;border:1px solid #111;font-weight: bold;">
                                  {{'PAYMENT_STATUS' | translate}}</td>
                                <td style=" text-align:end;border:1px solid #111; text-transform: uppercase;">
                                  {{purchaseOrderForInvoice.paymentStatus | paymentStatus}}
                                </td>
                              </tr> -->
                  </tbody>
                </table>
                <div>
                  <div *ngIf="purchaseOrderForInvoice.termAndCondition"
                    style="display:flex;flex-wrap: wrap;margin-top: 20px;">
                    <h5 class="heading" style="margin-top:2px;">{{'TERM_AND_CONDITION' | translate}}::</h5>
                    {{purchaseOrderForInvoice.termAndCondition}}
                  </div>
                  <div *ngIf="purchaseOrderForInvoice.termAndCondition"
                    style="display:flex;flex-wrap: wrap;margin-top: 5px; align-items:flex-start; font-size: 9px;">

                  </div>
                  <div *ngIf="purchaseOrderForInvoice.note" class="row"
                    style="display:flex;flex-wrap: wrap;margin-top: 20px;">
                    <h5 class="heading" style="margin-top:2px;">{{'NOTE' | translate}}::</h5>
                    {{purchaseOrderForInvoice.note}}
                  </div>
                  <div *ngIf="purchaseOrderForInvoice.note" class="row"
                    style="display:flex;flex-wrap: wrap; align-items:flex-start; font-size: 9px;">

                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot style="border:none;">
          <tr style="border:none;">
            <td style="border:none;">
              <div class="page-footer-space">&nbsp;</div>
            </td>
          </tr>
        </tfoot>
      </table>


    </div>
  </div>
</div>
