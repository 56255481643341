export class Languages {
  static languages: LanguageFlag[] = [
    {
      lang: 'en',
      name: 'English',
      flag: '../../../assets/images/flags/united-states.svg',
    },
    {
      lang: 'id',
      name: 'Indonesia',
      flag: '../../../assets/images/flags/indonesia.svg',
    },
  ];
}

export interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}
