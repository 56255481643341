<!-- /*left:-15420px*/ -->
<div style="position:absolute;left:-15420px;font-size: 10pt;">
  <div style="position:relative;width:21cm;height:14.8cm;">
    <div id="salesOrderForInvoice">
      <div class="container">
        <div class="page-header">
          <div class="brand-section">
            <div class="row" style="display: flex;flex-wrap: wrap;">
              <div style="width: 60%;flex: 0 0 auto;">
                <!-- <span
                          style="margin-bottom: 0px !important;font-size:14pt;text-transform: uppercase;">{{companyProfile?.title}}</span> -->
                <span
                  style="margin-bottom: 0px !important;font-size:14pt;text-transform: uppercase;">{{'SALES_ORDER_INVOICE' | translate}}</span>
                <div class="company-details">
                  <img *ngIf="!companyProfile.logoUrl" class="img-fluid" style="max-height:50px;
                                    object-position: left;" src="../../../assets/images/logo.png" />
                  <img *ngIf="companyProfile.logoUrl" class="img-fluid" style="max-height:50px;
                                    object-position: left;" [src]="companyProfile.logoUrl" />
                </div>
                <div>
                  <span style="font-size: 10pt;text-transform: uppercase;">{{'INVOICE_NUMBER' | translate}}:
                    {{salesOrderForInvoice?.orderNumber}} </span>
                </div>

              </div>
              <div style="width: 40%;flex: 0 0 auto;">

                <div>
                  <span style="font-size: 10pt;text-transform: uppercase;"> {{'INVOICE_DATE' | translate}} :</span> {{salesOrderForInvoice?.soCreatedDate
                                          | date:'dd/MM/yyyy'}}
                </div>
                <div *ngIf="salesOrderForInvoice?.customer?.mobileNo">
                  <b>{{'CUSTOMER' | translate | uppercase}}:</b>
                  {{salesOrderForInvoice?.customer?.customerName}} <br>
                  <div *ngIf="salesOrderForInvoice.customer?.address" style="width:50%;">
                    <span> {{salesOrderForInvoice.customer.address}} </span>
                  </div>
                </div>

                <div *ngIf="salesOrderForInvoice?.customer?.mobileNo">
                  <b>{{'PHONE' | translate}}:</b>
                  {{salesOrderForInvoice.customer.mobileNo}} <br>
                </div>
                <!-- <b>{{'FROM' | translate}} : {{companyProfile?.title}}</b><br>
                                      <div>
                                        <div *ngIf="companyProfile?.address">
                                          {{companyProfile?.address}} <br>
                                        </div>
                                        <div>
                                          <div *ngIf="companyProfile?.phone">
                                            - {{companyProfile?.phone}} <br>
                                          </div>
                                          <div *ngIf="companyProfile?.email">
                                                                <b>{{'EMAIL' | translate}}:</b>
                                                                {{companyProfile?.email}}
                                                            </div>
                                        </div>
                                      </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="page-footer">

          <div class="row" style="width:100%">
            <div style="display:flex;flex-wrap: wrap;">
              <div class="col-4" style="width:33%;">
                {{'BEST_REGARDS' |
                                    translate | uppercase}}
                <br><br><br>___________________<br>
                &nbsp;

              </div>
              <div class="col-4" style="width:33%;">
                {{'RECEIPT' | translate | uppercase}}
                <br><br><br>___________________<br>
                &nbsp;

              </div>
              <div class="col-4" style="width:33%;">

              </div>
            </div>
          </div>
        </div>

      </div>

      <table style="width:100%;border:none;">
        <thead>
          <tr style="border:none;">
            <td style="border:none;">
              <div class="page-header-space">&nbsp;</div>
            </td>
          </tr>
        </thead>
        <tbody style="border:none;">
          <tr style="border:none;">
            <td style="border:none;">
              <div class="page">
                <div>
                  <!-- <p class="heading" style="margin-top:2px;">{{'SALES_ORDER_ITEMS' | translate}} </p> -->
                  <table class="table-bordered"
                    style="background-color: #fff;width:100%;border-collapse:collapse;font-size: 10pt;">
                    <thead>
                      <tr style="border:1px solid #111;background-color:#f2f2f2;">
                        <th
                          style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:0.5cm;">
                          {{'NO.' | translate}}
                        </th>
                        <th
                          style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;">
                          {{'PRODUCT_NAME' | translate}}</th>
                        <th
                          style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:2cm;">
                          {{'QTY' | translate}}</th>
                        <th
                          style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:2.5cm;">
                          {{'UNIT_PRICE' | translate}}</th>

                        <!-- <th class="w-20"
                                  style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:1.68cm;">
                                  {{'UNIT' | translate}}</th> -->
                        <!-- <th
                                  style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:2.5cm;">
                                  {{'DISCOUNT' | translate}}</th> -->
                        <!-- <th class="w-20"
                                                    style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:3.2cm;">
                                                    {{'TAX' | translate}}</th> -->
                        <th
                          style="padding-top:08px;padding-bottom:08px;border:1px solid #111;text-transform: uppercase;width:3cm;">
                          {{'TOTAL_AMOUNT' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let salesItem of salesOrderItems;let i=index">
                        <td style="vertical-align:middle!important;text-align:center;border:1px solid #111;">
                          {{i+1}}</td>
                        <td style="vertical-align:middle!important;text-align:left;border:1px solid #111;">
                          {{salesItem.productName}}</td>
                        <td style="vertical-align:middle!important;text-align:left;border:1px solid #111;">
                          {{salesItem.quantity}} {{salesItem.unitName}}</td>
                        <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                          {{salesItem.unitPrice | customCurrency}}</td>
                        <!-- <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                                  {{salesItem.discount | customCurrency}}</td> -->
                        <!-- <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                                                    {{salesItem.taxValue | customCurrency}}<br>
                                                    <span style="font-size: 10pt;"
                                                        *ngFor="let tax of salesItem.salesOrderItemTaxes;let last=last">
                                                        {{tax.taxName}}({{tax.taxPercentage}}%) <span *ngIf="!last">, </span>
                                                    </span>
                                                </td> -->
                        <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                          {{(salesItem?.quantity * salesItem?.unitPrice) - salesItem.discount +
                                                    salesItem.taxValue | customCurrency }}
                        </td>
                      </tr>
                      <tr
                        style="vertical-align:middle!important;border:1px solid #111;background-color: blanchedalmond;"
                        *ngIf="salesOrderReturnsItems.length > 0">
                        <td colspan="100%"><strong>{{'RETURNED_ITEMS' | translate | uppercase}}</strong></td>
                      </tr>
                      <tr style="background-color: blanchedalmond;"
                        *ngFor="let salesItem of salesOrderReturnsItems;let i=index">
                        <td style="vertical-align:middle!important;text-align:center;border:1px solid #111;">
                          {{i+1}}</td>
                        <td style="vertical-align:middle!important;text-align:left;border:1px solid #111;">
                          {{salesItem.productName}}</td>

                        <td style="vertical-align:middle!important;text-align:left;border:1px solid #111;">
                          {{-1 *salesItem.quantity}} {{salesItem.unitName}}</td>
                        <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                          {{salesItem.unitPrice | customCurrency}}</td>

                        <!-- <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                                  {{-1 *salesItem.discount | customCurrency}}</td> -->
                        <!-- <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                                                    {{-1 *salesItem.taxValue | customCurrency}}<br>
                                                    <span style="font-size: 10pt;"
                                                        *ngFor="let tax of salesItem.purchaseOrderItemTaxes;let last=last">
                                                        {{tax.taxName}}({{tax.taxPercentage}}%) <span *ngIf="!last">, </span>
                                                    </span>
                                                </td> -->
                        <td style="vertical-align:middle!important;text-align:right;border:1px solid #111;">
                          {{-1 *(salesItem?.quantity * salesItem?.unitPrice) - salesItem.discount +
                                                    salesItem.taxValue | customCurrency }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="text-right"
                          style="text-align:end;border:1px solid #111;font-weight: bold;">
                          {{'TOTAL_ITEM' | translate | uppercase}}
                        </td>
                        <td class="text-center" style="text-align:left;border:1px solid #111;font-weight: bold;">
                          {{salesOrderForInvoice.totalQuantity}} {{'ITEM' | translate | uppercase}}
                        </td>
                        <td colspan="1" class="text-right"
                          style="text-align:end;border:1px solid #111;font-weight: bold;">
                          {{'SUBTOTAL' | translate | uppercase}}
                        </td>
                        <!-- <td class="text-right" style="text-align:end;border:1px solid #111;font-weight: bold;">
                                  {{salesOrderForInvoice.totalDiscount | customCurrency}}
                                </td> -->
                        <!-- <td class="text-right"
                                                    style="text-align:end;border:1px solid #111;font-weight: bold;">
                                                    {{salesOrderForInvoice.totalTax | customCurrency}}
                                                </td> -->

                        <td style="text-align:end;border:1px solid #111;font-weight: bold;">
                          {{salesOrderForInvoice.totalAmount | customCurrency}}</td>
                      </tr>
                      <!-- <tr>
                                <td colspan="5" class="text-right" style="text-align:end;border:1px solid #111;font-weight: bold;">
                                  {{'PAID' | translate | uppercase}}</td>
                                <td style="text-align:end;border:1px solid #111;">
                                  {{salesOrderForInvoice.totalPaidAmount | customCurrency}} </td>
                              </tr> -->
                      <tr>
                        <td colspan="4" class="text-right"
                          style="text-align:end;border:1px solid #111;font-weight: bold;">
                          {{'TOTAL' | translate | uppercase}}</td>
                        <td style=" text-align:end;border:1px solid #111;">
                          {{salesOrderForInvoice.totalAmount-salesOrderForInvoice.totalPaidAmount |
                                                    customCurrency}}
                        </td>
                      </tr>
                      <!-- <tr>
                                <td colspan="5" class="text-right" style="text-align:end;border:1px solid #111;font-weight: bold;">
                                  {{'PAYMENT_STATUS' | translate | uppercase}}</td>
                                <td style=" text-align:end;border:1px solid #111; text-transform: uppercase;">
                                  {{salesOrderForInvoice.paymentStatus | paymentStatus}}
                                </td>
                              </tr> -->
                    </tbody>
                  </table>
                  <div>
                    <div *ngIf="salesOrderForInvoice.termAndCondition"
                      style="display:flex;flex-wrap: wrap;margin-top: 20px;">
                      <h5 class="heading" style="margin-top:2px;">{{'TERM_AND_CONDITION' | translate}}::</h5>
                      {{salesOrderForInvoice.termAndCondition}}
                    </div>
                    <div *ngIf="salesOrderForInvoice.termAndCondition"
                      style="display:flex;flex-wrap: wrap;margin-top: 5px; align-items:flex-start; font-size: 9px;">

                    </div>
                    <div *ngIf="salesOrderForInvoice.note" class="row"
                      style="display:flex;flex-wrap: wrap;margin-top: 20px;">
                      <h5 class="heading" style="margin-top:2px;">{{'NOTE' | translate}}::</h5>
                      {{salesOrderForInvoice.note}}
                    </div>
                  </div>


                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot style="border:none;">
          <tr style="border:none;">
            <td style="border:none;">
              <div class="page-footer-space">&nbsp;</div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

  </div>
</div>
